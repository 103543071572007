import React, {useEffect, useState} from 'react';
import {Container} from "react-bootstrap";

const ContainerWrapper = ({id, className, children}) => {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    
    const h = matches ? 'vh-100': 'h-100';
    const classList = className ? [...className, h] : [h];
    
    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);
    
    return (
        <Container id={id} className={classList.join(' ')}>
            {children}
        </Container>
    );
};

export default ContainerWrapper;