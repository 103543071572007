import React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import ContainerWrapper from "./ContainerWrapper";
import {useTranslation} from "react-i18next";

const Services = () => {

    const {t} = useTranslation();

    return (
        <ContainerWrapper id="services">
            <Row>
                <Col>
                    <span className="font-16-600">{t('nav.services')}</span>
                </Col>
            </Row>
            <Row className="pt-3">
                <Col>
                    <span className="font-14-400">{t('services.sub-title')}:</span>
                </Col>
            </Row>
            {
                [
                    {
                        title: t('services.title-1'),
                        elements: [
                            t('services.text1'),
                            t('services.text2'),
                            t('services.text3'),
                            t('services.text4'),
                        ]
                    },
                    {
                        title: t('services.title-2'),
                        elements: [
                            t('services.text5'),
                            t('services.text6'),
                            t('services.text7'),
                            t('services.text8'),
                        ]
                    }
                ].map((service, index) => (
                    <Row>
                        <Row className="font-14-400 gy-2">
                            <Col className="d-flex align-items-center gap-1">
                                <span className="material-symbols-outlined el-color" style={{fontSize:'16px'}}>check_circle</span>{service.title}</Col>
                        </Row>
                        <Row xs={1} md={2} className="gy-2">
                            {service.elements.map((el, index) => (
                                <Col key={index}>
                                    <Card className="card-border">
                                        <Card.Body
                                            className="d-flex align-items-center justify-content-center font-14-400"
                                            style={{height: "70px"}}>
                                            <Card.Text className="font-size-media-marker">
                                                {el}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Row>
                ))
            }
        </ContainerWrapper>
    );
};

export default Services;