import React from 'react';
import {Col, Row} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import ContainerWrapper from "./ContainerWrapper";
import {useTranslation} from "react-i18next";

const Home = () => {

    const {t, i18n} = useTranslation();

    return (
        <ContainerWrapper id="home" className={['d-flex', 'flex-column']}>
            <Row className="pt-5">
                <Col className="d-flex">
                    <Image src={i18n.language === 'ru' 
                        ? process.env.PUBLIC_URL + '/images/logo_ru.svg' 
                        : process.env.PUBLIC_URL + '/images/logo_en.svg'} rounded className="w-50"/>
                </Col>
            </Row>
            <Row className="pt-5">
                <Col className="d-flex">
                    {t('home.text1')}
                </Col>
            </Row>
            <Row className="flex-grow-1">
                <Col className="d-flex">
                    {t('home.text2')}
                </Col>
            </Row>
            <Row md={3} sm={1} xs={1}>
                <Col className="d-flex align-items-center mb-1">
                    <Col className="home-icon">
                        <span className="material-symbols-outlined el-color fs-36px">call</span>
                    </Col>
                    <Col style={{flex: "1 2 auto"}}>
                        <Row className="font-14-600">{t('contacts.phone1')}</Row>
                        <Row className="font-14-600">{t('contacts.phone2')}</Row>
                    </Col>
                </Col>
                <Col className="d-flex align-items-center mb-1">
                    <Col className="home-icon">
                        <span className="material-symbols-outlined el-color fs-36px">mail</span>
                    </Col>
                    <Col style={{flex: "1 2 auto"}}>
                        <Row className="font-14-600">{t('contacts.email1')}</Row>
                        <Row className="font-14-600">{t('contacts.email2')}</Row>
                    </Col>
                </Col>
                <Col className="d-flex align-items-center">
                    <Col className="home-icon">
                        <span className="material-symbols-outlined el-color fs-36px">location_on</span>
                    </Col>
                    <Col style={{flex: "1 2 auto"}} className="font-14-600">
                        <Row>
                            {t('contacts.address-text-full')}
                        </Row>
                    </Col>
                </Col>
            </Row>
            <Row>
                <span className="text-center pt-3" style={{color:'red'}}>
                    {t('home.text3')}
                </span>
            </Row>
        </ContainerWrapper>
    );
};

export default Home;