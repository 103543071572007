import React from 'react';
import ContainerWrapper from "../components/ContainerWrapper";
import {Card, Col, Container, Row, Stack, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const SoftGelatinCapsuleProductionLinePage = () => {

    const {t, i18n} = useTranslation();

    const companiesList =
        [{name: "Patheon (ThermoFisher)", country: t('SoftGelatinCapsuleProductionLinePage.Netherlands')},
            {name: "CHC company", country: t('SoftGelatinCapsuleProductionLinePage.Poland')},
            {name: "COLFARM", country: t('SoftGelatinCapsuleProductionLinePage.Poland')},
            {name: "Ayada", country: t('SoftGelatinCapsuleProductionLinePage.Germany')},
            {name: "JUVA Pharma company", country: t('SoftGelatinCapsuleProductionLinePage.Hungary')},
            {name: "Lambo", country: t('SoftGelatinCapsuleProductionLinePage.Belgium')},
            {name: "AUCTA Pharma", country: t('SoftGelatinCapsuleProductionLinePage.USA')},
            {name: "Procaps Groups", country: t('SoftGelatinCapsuleProductionLinePage.USA')},
            {name: "Best Formulations Inc.", country: t('SoftGelatinCapsuleProductionLinePage.USA')},
            {name: "Chalver Group", country: t('SoftGelatinCapsuleProductionLinePage.USA')},
            {name: "e2e pharma", country: t('SoftGelatinCapsuleProductionLinePage.USA')},
            {name: "Vireo Health Group", country: t('SoftGelatinCapsuleProductionLinePage.USA')},
            {name: "Softgel Technology", country: t('SoftGelatinCapsuleProductionLinePage.USA')},
            {name: "Capcium", country: t('SoftGelatinCapsuleProductionLinePage.Canada')},
            {name: "Medipharm", country: t('SoftGelatinCapsuleProductionLinePage.Australia')},
            {name: "GMP pharma", country: t('SoftGelatinCapsuleProductionLinePage.Australia')},
            {name: "Nature care company", country: t('SoftGelatinCapsuleProductionLinePage.Australia')},
            {name: "Hormart Pharma company", country: t('SoftGelatinCapsuleProductionLinePage.Australia')},
            {name: "Freegrove Pharma company", country: t('SoftGelatinCapsuleProductionLinePage.Australia')},
            {name: "Catalent Group", country: t('SoftGelatinCapsuleProductionLinePage.Argentina')},
            {name: "Patrio Company", country: t('SoftGelatinCapsuleProductionLinePage.Brazil')},
            {name: "Biocaps company", country: t('SoftGelatinCapsuleProductionLinePage.Mexico')},
            {name: "BAHRAIN PHARMACY", country: t('SoftGelatinCapsuleProductionLinePage.Bahrain')}]

    return (
        <div className="custom-page">
            <ContainerWrapper>
                <Stack gap={2}>
                    <Card className="card-border-wrapper pt-2 pb-2 card1">
                        <Stack gap={1}>
                            <Container>
                                <Row>
                                    <Col>
                                        <span
                                            className='fw-bold'>{t('SoftGelatinCapsuleProductionLinePage.text1')}</span> {t('SoftGelatinCapsuleProductionLinePage.text2')}
                                    </Col>
                                </Row>
                                <Row className='pt-2'>
                                    <Col>
                                        {t('SoftGelatinCapsuleProductionLinePage.text3')}, <span
                                        className='fw-bold'>{t('SoftGelatinCapsuleProductionLinePage.text1')}</span> {t('SoftGelatinCapsuleProductionLinePage.text4')}
                                    </Col>
                                </Row>
                                <Row className='pt-2'>
                                    <Col>
                                        {t('SoftGelatinCapsuleProductionLinePage.text5')}
                                    </Col>
                                </Row>
                                <Row className='pt-4'>
                                    <Card.Text
                                        className="fw-bold">{t('SoftGelatinCapsuleProductionLinePage.text6')}:</Card.Text>
                                </Row>
                                <Row className='pt-1'>
                                    <Col>
                                        <Stack gap={2}>
                                            <Card className="card-border"><Card.Text
                                                className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text7')}</Card.Text></Card>
                                            <Card className="card-border"><Card.Text
                                                className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text8')}</Card.Text></Card>
                                            <Card className="card-border"><Card.Text
                                                className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text9')}</Card.Text></Card>
                                        </Stack>
                                    </Col>
                                    <Col>
                                        <Stack gap={2}>
                                            <Card className="card-border"><Card.Text
                                                className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text10')}</Card.Text></Card>
                                            <Card className="card-border"><Card.Text
                                                className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text11')}</Card.Text></Card>
                                            <Card className="card-border"><Card.Text
                                                className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text12')}</Card.Text></Card>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className='pt-4'>
                                    <Card.Text
                                        className="fw-bold">{t('SoftGelatinCapsuleProductionLinePage.text13')}</Card.Text>
                                </Row>
                                <Row className='pt-1'>
                                    <Col>
                                        <Stack>
                                            <Card className="card-border"><Card.Text
                                                className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text14')}</Card.Text></Card>
                                        </Stack>
                                    </Col>
                                    <Col>
                                        <Stack>
                                            <Card className="card-border"><Card.Text
                                                className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text15')}</Card.Text></Card>
                                        </Stack>
                                    </Col>
                                </Row>
                            </Container>
                        </Stack>
                    </Card>
                    <Card className="card-border-wrapper pt-2 pb-2 card2">
                        <Stack gap={1}>
                            <Container>
                                <Row>
                                    <Col>
                                        <Card.Text
                                            className="text-center fw-bold">{t('SoftGelatinCapsuleProductionLinePage.text16')}</Card.Text>
                                    </Col>
                                </Row>
                                <Row className="p-2">
                                    <Card className="card-border">
                                        <Card.Text
                                            className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text17')}</Card.Text>
                                    </Card>
                                </Row>
                                <Row className="p-2">
                                    <Card className="card-border">
                                        <Card.Text
                                            className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text18')}</Card.Text>
                                    </Card>
                                </Row>
                                <Row className="p-2">
                                    <Card className="card-border">
                                        <Card.Text
                                            className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text19')}</Card.Text>
                                    </Card>
                                </Row>
                                <Row className="p-2">
                                    <Card className="card-border">
                                        <Card.Text
                                            className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text20')}</Card.Text>
                                    </Card>
                                </Row>
                                <Row className="p-2">
                                    <Card className="card-border">
                                        <Card.Text
                                            className="text-center">{t('SoftGelatinCapsuleProductionLinePage.text21')}</Card.Text>
                                    </Card>
                                </Row>
                                <Row className="p-2">
                                    <Card className="card-border">
                                        <Card.Text
                                            className="text-center fw-bold">{t('SoftGelatinCapsuleProductionLinePage.text22')}</Card.Text>
                                    </Card>
                                </Row>
                            </Container>
                        </Stack>
                    </Card>
                    <Card className="card-border-wrapper card3">
                        <Card.Body>
                            <Card.Img variant="top"
                                      src={i18n.language === 'ru'
                                          ? process.env.PUBLIC_URL + `/pages/SoftGelatinCapsuleProductionLinePage/basic_production_flow/Osnovnoy_proizvodstvennyi_process.svg`
                                          : process.env.PUBLIC_URL + `/pages/SoftGelatinCapsuleProductionLinePage/basic_production_flow/basic_production_flow.png`}/>
                        </Card.Body>
                    </Card>
                    <Card className="card-border-wrapper card4">
                        <Card.Title
                            className='text-center pt-2'>{t('SoftGelatinCapsuleProductionLinePage.text23')}</Card.Title>
                        <Card.Body>
                            <Container>
                                <Row className='d-flex justify-content-between'>
                                    <Col><Card.Text
                                        className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text24')}</Card.Text></Col>
                                    <Col><Card.Text
                                        className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text25')}</Card.Text></Col>
                                    <Col><Card.Text
                                        className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text26')}</Card.Text></Col>
                                    <Col><Card.Text
                                        className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text27')}</Card.Text></Col>
                                </Row>
                            </Container>
                            <Card.Img variant="top"
                                      className='pt-2'
                                      src={process.env.PUBLIC_URL + `/pages/SoftGelatinCapsuleProductionLinePage/softgel_capsule_production_line/SOFTGEL_CAPSULE_PRODUCTION_LINE.png`}/>
                        </Card.Body>
                    </Card>
                    <Card className="card-border-wrapper card5">
                        <Card.Header
                            className='text-center pt-2 fw-600'><Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text28')}</Card.Text></Card.Header>
                        <Card.Body>
                            <Container>
                                <Row className='d-flex justify-content-between'>
                                    <Col>
                                        <Stack>
                                            <Card.Text
                                                className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text29')}</Card.Text>
                                            <Card.Img variant="top"
                                                      className='pt-2'
                                                      src={process.env.PUBLIC_URL
                                                          + `/pages/SoftGelatinCapsuleProductionLinePage/Material_and_animal_plant_gelatin_preparation_and_storage_system_1/Material_and_animal_plant_gelatin_preparation_and_storage_system_1_1.png`}/>
                                        </Stack>
                                    </Col>
                                    <Col>
                                        <Stack>
                                            <Card.Text
                                                className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text30')}</Card.Text>
                                            <Card.Img variant="top"
                                                      className='pt-2'
                                                      style={{height: '874px'}}
                                                      src={process.env.PUBLIC_URL
                                                          + `/pages/SoftGelatinCapsuleProductionLinePage/Material_and_animal_plant_gelatin_preparation_and_storage_system_1/Material_and_animal_plant_gelatin_preparation_and_storage_system_1_3.png`}/>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className="pt-3">
                                    <Col>
                                        <Stack>
                                            <Card.Text
                                                className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text31')}</Card.Text>
                                            <Card.Img variant="top"
                                                      className='pt-2'
                                                      src={process.env.PUBLIC_URL
                                                          + `/pages/SoftGelatinCapsuleProductionLinePage/Material_and_animal_plant_gelatin_preparation_and_storage_system_1/_Material_and_animal_plant_gelatin_preparation_and_storage_system_1_4.png`}/>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className='d-flex justify-content-between pt-3'>
                                    <Card.Header
                                        className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text32')}</Card.Header>
                                </Row>
                                <Row className='d-flex justify-content-between pt-2'>
                                    <Col>
                                        <Stack>
                                            <Card.Img variant="top"
                                                      className='pt-2'
                                                      style={{height: '550px'}}
                                                      src={process.env.PUBLIC_URL
                                                          + `/pages/SoftGelatinCapsuleProductionLinePage/Material_and_animal_plant_gelatin_preparation_and_storage_system_2/Material_and_animal_plant_gelatin_preparation_and_storage_system_2_1.png`}/>
                                            <Card.Text
                                                className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text33')}</Card.Text>
                                        </Stack>
                                    </Col>
                                    <Col>
                                        <Stack>
                                            <Card.Img variant="top"
                                                      className='pt-2'
                                                      src={process.env.PUBLIC_URL
                                                          + `/pages/SoftGelatinCapsuleProductionLinePage/Material_and_animal_plant_gelatin_preparation_and_storage_system_2/Material_and_animal_plant_gelatin_preparation_and_storage_system_2_2.png`}/>
                                            <Card.Text
                                                className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text34')}</Card.Text>
                                        </Stack>
                                    </Col>
                                </Row>

                            </Container>
                        </Card.Body>
                    </Card>
                    <Card className="card-border-wrapper card6">
                        <Card.Header
                            className='text-center pt-2 fw-600'><Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text35')}</Card.Text></Card.Header>
                        <Card.Body>
                            <Container>
                                <Row>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text36')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text36_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Softgel_Encapsulation_Machine_1/Softgel_Encapsulation_Machine_1_1.png`}/>
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text37')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text37_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Softgel_Encapsulation_Machine_1/Softgel_Encapsulation_Machine_1_2.png`}/>
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text38')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text38_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Softgel_Encapsulation_Machine_1/Softgel_Encapsulation_Machine_1_3.png`}/>
                                    </Col>
                                </Row>
                                <Row className='pt-5'>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text39')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text39_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Softgel_Encapsulation_Machine_2/Softgel_Encapsulation_Machine_2_1.png`}/>
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text40')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text40_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Softgel_Encapsulation_Machine_2/Softgel_Encapsulation_Machine_2_2.png`}/>
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text41')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text41_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Softgel_Encapsulation_Machine_2/Softgel_Encapsulation_Machine_2_3.png`}/>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                    <Card className="card-border-wrapper card7">
                        <Card.Header className='text-center pt-2 fw-600'>
                            <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.drying_system')}</Card.Text>
                            <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text42')}</Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <Container>
                                <Row>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text43')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text43_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_1/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_1_1.png`}/>
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text44')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text44_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_1/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_1_2.png`}/>
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text45')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text45_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_1/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_1_3.png`}/>
                                    </Col>
                                </Row>
                                <Row className='pt-5'>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text46')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text46_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_2/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_2_1.png`}/>
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text47')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text47_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_2/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_2_2.png`}/>
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text48')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text48_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_2/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_2_3.png`}/>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                    <Card className="card-border-wrapper card8">
                        <Card.Header className='text-center pt-2 fw-600'>
                            <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.drying_system')}</Card.Text>
                            <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text49')}</Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <Container>
                                <Row>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text50')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text50_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_3/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_3_1.png`}/>
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text51')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text51_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_3/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_3_2.png`}/>
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text52')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text52_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_3/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_3_3.png`}/>
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text53')}</span>
                                            <span>{t('SoftGelatinCapsuleProductionLinePage.text53_1')}</span>
                                        </div>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_3/Drying_System_Capsules_Shaping_System_Initial_Drying_Final_Drying_3_4.png`}/>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                    <Card className="card-border-wrapper card9 fw-600">
                        <Card.Header className='text-center pt-2'>
                            <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text54')}</Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <Container>
                                <Row>
                                    <Col>
                                        <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text55')}</Card.Text>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Capsules_Automatic_Inspection_System/Capsules_Automatic_Inspection_System_1.png`}/>
                                    </Col>
                                    <Col>
                                        <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text56')}</Card.Text>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Capsules_Automatic_Inspection_System/Capsules_Automatic_Inspection_System_2.png`}/>
                                    </Col>
                                    <Col>
                                        <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text57')}</Card.Text>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Capsules_Automatic_Inspection_System/Capsules_Automatic_Inspection_System_3.png`}/>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                    <Card className="card-border-wrapper card10 fw-600">
                        <Card.Header className='text-center pt-2'>
                            <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text58')}</Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <Container>
                                <Row>
                                    <Col>
                                        <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text59')}</Card.Text>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Auxiliary_Equipment_1/Auxiliary_Equipment_1_1.png`}/>
                                    </Col>
                                    <Col>
                                        <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text60')}</Card.Text>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Auxiliary_Equipment_1/Auxiliary_Equipment_1_2.png`}/>
                                    </Col>
                                    <Col>
                                        <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text61')}</Card.Text>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Auxiliary_Equipment_1/Auxiliary_Equipment_1_3.png`}/>
                                    </Col>
                                </Row>
                                <Row className='pt-5'>
                                    <Col>
                                        <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text62')}</Card.Text>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Auxiliary_Equipment_2/Auxiliary_Equipment_2_1.png`}/>
                                    </Col>
                                    <Col>
                                        <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text63')}</Card.Text>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Auxiliary_Equipment_2/Auxiliary_Equipment_2_2.png`}/>
                                    </Col>
                                    <Col>
                                        <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text64')}</Card.Text>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/Auxiliary_Equipment_2/Auxiliary_Equipment_2_3.png`}/>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                    <Card className="card-border-wrapper card11">
                        <Card.Body>
                            <Container>
                                <Row>
                                    <Col>
                                        <Card.Text>{t('SoftGelatinCapsuleProductionLinePage.text65')}</Card.Text>

                                        <Stack gap={2}>
                                            <Card className="card-border">
                                                <Card.Text
                                                    className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text66')}</Card.Text>
                                            </Card>
                                            <Card className="card-border">
                                                <Card.Text
                                                    className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text67')}</Card.Text>
                                            </Card>
                                            <Card className="card-border">
                                                <Card.Text
                                                    className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text68')}</Card.Text>
                                            </Card>
                                            <Card className="card-border">
                                                <Card.Text
                                                    className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text69')}</Card.Text>
                                            </Card>
                                        </Stack>
                                    </Col>
                                    <Col>
                                        <Card.Img variant="top"
                                                  className='pt-2'
                                                  src={process.env.PUBLIC_URL
                                                      + `/pages/SoftGelatinCapsuleProductionLinePage/SHAPE_OF_PRODUCED_CAPSULES.jpg`}/>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                    <Card className="card-border-wrapper card12">
                        <Card.Header
                            className='text-center'>{t('SoftGelatinCapsuleProductionLinePage.text70')}</Card.Header>
                        <Card.Body>
                            <Container>
                                <Row>
                                    <Col>
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                            <tr>
                                                <th style={{width:'60px'}}>{t('SoftGelatinCapsuleProductionLinePage.text71')}</th>
                                                <th>{t('SoftGelatinCapsuleProductionLinePage.text72')}</th>
                                                <th>{t('SoftGelatinCapsuleProductionLinePage.text73')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                companiesList.map((s, i) => (<tr>
                                                    <td>{i + 1}.</td>
                                                    <td>{s.name}</td>
                                                    <td>{s.country}</td>
                                                </tr>))
                                            }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Stack>
            </ContainerWrapper>
        </div>
    );
};

export default SoftGelatinCapsuleProductionLinePage;