import React from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import ContainerWrapper from "./ContainerWrapper";
import {useTranslation} from "react-i18next";

const Service = () => {

    const {t} = useTranslation();

    return (
        <ContainerWrapper id="service">
            <Row className="mb-3">
                <Col>
                    <span className="font-16-600">{t('service.title')}</span>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <span className="font-14-400">{t('service.sub-title')}:</span>
                </Col>
            </Row>
            <Row xs={1} md={1} className="gy-2">
                {[
                    t('service.text1'),
                    t('service.text2'),
                    t('service.text3'),
                    t('service.text4'),
                    t('service.text5'),
                    t('service.text6'),
                    t('service.text9'),
                    t('service.text10'),
                    t('service.text11')
                ].map((el, index) => (
                    <Col key={index}>
                        <Card className="card-border">
                            <Card.Body
                                className="d-flex align-items-center justify-content-center font-14-400"
                                style={{height: "50px"}}>
                                <Card.Text className="font-size-media-marker">
                                    {el}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </ContainerWrapper>
    )
        ;
};

export default Service;