import React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import ContainerWrapper from "./ContainerWrapper";
import {useTranslation} from "react-i18next";

const About = () => {

    const {t} = useTranslation();
    return (
        <ContainerWrapper id={'about'}>
            <Row>
                <Col>
                    {
                        t('nav.about')
                    }
                </Col>
            </Row>
            <Row className="pt-3">
                <Col>
                    <span className="font-14-600">{t('about.title')}</span>
                    <span className="font-14-400">{t('about.sub-title')}</span>
                </Col>
            </Row>
            <Row>
                <Row xs={1} md={2} sm={2} className="gy-4">
                    {[
                        t('about.text1'),
                        t('about.text2'),
                        t('about.text3'),
                        t('about.text4'),
                        t('about.text5'),
                        t('about.text6')
                    ].map((content, idx) => (
                        <Col key={idx}>
                            <Card className="card-border" style={{height: '150px'}}>
                                <Card.Body className="d-flex align-items-center">
                                    <Card.Text className="font-size-media-marker">
                                        {content}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Row>
        </ContainerWrapper>
    );
};

export default About;