import React from 'react';
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Equipment from "./components/Equipment";
import Certificate from "./components/Certificate";
import Reviews from "./components/Reviews";
import Service from "./components/Service";

const App = () => {
    return (
        <>
            <Home/>

            <About/>

            <Services/>

            <Equipment/>

            <Certificate/>

            <Reviews/>

            <Service/>
        </>
    );
};

export default App;