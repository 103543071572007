import React, {Suspense, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import {Button, ButtonGroup, Container, Nav, Navbar, Spinner} from "react-bootstrap";
import About from "./components/About";
import ContactsModal from "./components/ContactsModal";
import './i18n';
import {useTranslation} from "react-i18next";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import App from "./App";
import SoftGelatinCapsuleProductionLinePage from "./custom_pages/SoftGelatinCapsuleProductionLinePage";


const Index = () => {
    const [modalShow, setModalShow] = useState(false);

    const {t, i18n} = useTranslation();

    return (
        <>
            <Navbar expand="md" className="bg-body-tertiary" fixed="top">
                <Container fluid="md">
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="ms-auto my-2 my-lg-0 flex-grow-1"
                            navbarScroll
                            justify={true}
                        >
                            <Nav.Link href="/#home" className="font-14-400">{t('nav.home')}</Nav.Link>
                            <Nav.Link href="/#about" className="font-14-400">{t('nav.about')}</Nav.Link>
                            <Nav.Link href="/#services" className="font-14-400">{t('nav.services')}</Nav.Link>
                            <Nav.Link href="/#equipment" className="font-14-400">{t('nav.equipment')}</Nav.Link>
                            <Nav.Link href="/SoftGelatinCapsuleProductionLine" className="font-14-400">{t('nav.link.SoftGelatinCapsuleProductionLine')}</Nav.Link>
                            <Nav.Link href="/#certificate" className="font-14-400">{t('nav.certificate')}</Nav.Link>
                            {/*<Nav.Link href="/#reviews" className="font-14-400">{t('nav.reviews')}</Nav.Link>*/}
                            <Nav.Link href="/#service" className="font-14-400">{t('nav.service')}</Nav.Link>
                            <Nav.Link href="#" className="font-14-400"
                                      onClick={() => setModalShow(true)}>{t('nav.contacts')}</Nav.Link>
                            <ButtonGroup aria-label="lng">
                                <Button variant="link" onClick={() => i18n.changeLanguage('ru')}>RU</Button>
                                <Button variant="link" onClick={() => i18n.changeLanguage('en')}>EN</Button>
                            </ButtonGroup>
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App/>}/>
                    <Route path="/SoftGelatinCapsuleProductionLine" element={<SoftGelatinCapsuleProductionLinePage/>}/>
                    {/*<Route path="*" element={<NotFound />} />*/}
                </Routes>
            </BrowserRouter>

            <ContactsModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
};

export default Index;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Suspense fallback={
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    }>
        <Index/>
    </Suspense>
);