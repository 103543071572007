import React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import ContainerWrapper from "./ContainerWrapper";
import {useTranslation} from "react-i18next";

const Certificate = () => {

    const {t} = useTranslation();

    const files = Array.from({length: 10}, (_, i) =>
        (
            {
                jpg: process.env.PUBLIC_URL + `/files/certificates/cer_image_${i + 1}.jpg`,
                pdf: process.env.PUBLIC_URL + `/files/certificates/cer_image_${i + 1}.pdf`,
            }
        ))

    return (
        <ContainerWrapper id="certificate">
            <Row className="mb-3">
                <Col>
                    <span className="font-16-600">{t('certificate.title')}</span>
                </Col>
            </Row>
            <Row xs={1} md={5} className="gy-2">
                {files.map((el, index) => (
                    <Col key={index}>
                        <Card className="card-border">
                            <Card.Img variant="top" src={el.jpg} onClick={() => (
                                window.open(el.pdf)
                            )}/>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row className="mt-5">
                <Col>
                    <span
                        className="font-16-600 font-style-italic">{t('certificate.footer')}
                    </span>
                </Col>
            </Row>
        </ContainerWrapper>
    )
        ;
};

export default Certificate;