import React from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const ContactsModal = (props) => {

    const {t} = useTranslation();

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h4>{t('contacts.title')}</h4>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <span className="font-14-600">{t('contacts.address')}:</span><span
                    className="font-14-400">{t('contacts.address-text')}</span>
                </p>
                <p style={{marginBottom: '0px'}}>
                    <span className="font-14-600">{t('contacts.phone')}/{t('contacts.fax')}:</span>
                </p>
                <p style={{marginBottom: '0px'}}><a href={`tel:${t('contacts.phone1')}`} className="font-14-400">{t('contacts.phone1')}</a><span> - {t('contacts.text1')}</span></p>
                <p><a href={`tel:${t('contacts.phone2')}`}
                      className="font-14-400">{t('contacts.phone2')}</a><span> - {t('contacts.text2')}</span>
                </p>
                <p style={{marginBottom: '0px'}}><span className="font-14-600">{t('contacts.email')}:</span></p>
                <p style={{marginBottom: '0px'}}><a href={`mailto:${t('contacts.email1')}`}
                                                    className="font-14-400">{t('contacts.email1')}</a><span> – {t('contacts.text3')}</span>
                </p>
                <p><a href={`mailto:${t('contacts.email2')}`}
                      className="font-14-400">{t('contacts.email2')}</a><span> – {t('contacts.text4')}</span>
                </p>
            </Modal.Body>
        </Modal>
    );
};

export default ContactsModal;