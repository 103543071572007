import React from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import ContainerWrapper from "./ContainerWrapper";
import {useTranslation} from "react-i18next";

const Equipment = () => {
    const {t} = useTranslation();
    
    return (
        <ContainerWrapper id="equipment" >
            <Row className="mx-auto mb-3">
                <Col>
                    <span className="font-16-600">{t('equipment.title')}</span>
                </Col>
            </Row>
            <Row xs={1} md={1} className="gy-2">
                {[
                    t('equipment.text1'),
                    t('equipment.text2'),
                    t('equipment.text3'),
                    t('equipment.text4'),
                    t('equipment.text5'),
                    t('equipment.text6'),
                    t('equipment.text7'),
                    t('equipment.text8'),
                    t('equipment.text9'),
                    t('equipment.text11'),
                    t('equipment.text10')
                ].map((el, index) => (
                    <Col key={index}>
                        <Card  className="card-border">
                            <Card.Body
                                className="d-flex align-items-center justify-content-center font-14-400"
                                style={{height: "50px"}}>
                                <Card.Text className="font-size-media-marker">
                                    {el}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </ContainerWrapper>
    );
};

export default Equipment;