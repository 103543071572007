import React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import ContainerWrapper from "./ContainerWrapper";
import {useTranslation} from "react-i18next";

const Reviews = () => {

    const {t} = useTranslation();

    const files = Array.from({length: 10}, (_, i) =>
        (
            {
                jpg: process.env.PUBLIC_URL + `/files/reviews/review_${i + 1}.jpg`,
                pdf: process.env.PUBLIC_URL + `/files/reviews/review_${i + 1}.pdf`,
            }
        ))

    return (
        <></>
    );
};

export default Reviews;

// restore after approve
// <ContainerWrapper id="reviews">
//     <Row className="mb-3">
//         <Col>
//             <span className="font-16-600">{t('reviews.title')}</span>
//         </Col>
//     </Row>
//     <Row xs={1} md={5} className="gy-2">
//         {
//             files.map((el, index) => (
//                 <Col key={index}>
//                     <Card className="card-border">
//                         <Card className="card-border">
//                             <Card.Img variant="top" src={el.jpg} onClick={() => (
//                                 window.open(el.pdf)
//                             )}/>
//                         </Card>
//                     </Card>
//                 </Col>
//             ))}
//     </Row>
// </ContainerWrapper>